import React from "react";
import { AddFlex, PrimaryActionButton } from "../reusableStyles";

function OtherDomainLoginDialog({ domain, redirectUrl }) {
  return (
    <AddFlex flexDirection="column" padding="30px">
      <h4>You are already Signed with {domain}</h4>
      <PrimaryActionButton onClick={redirectUrl}>Redirect</PrimaryActionButton>
    </AddFlex>
  );
}

export default OtherDomainLoginDialog;
