import React, { useEffect, useState } from "react";
import { AddFlex } from "../reusableStyles";
import styled from "styled-components";
import LoginWrapper from "./LoginWrapper";
import Loading from "./Loading";
import customClientAxios from "../clientAxios";
import { CheckAuthStatus, FetchSubdomainClient } from "../ApiEndPoints";
import { authenticate } from "../firebase-config";
import { getAdminFrontendURL } from "../axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { signOut } from "firebase/auth";
import useWindowSize from "./useWindowSize";
const ImageHolderDiv = styled(AddFlex)`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  background-image: ${(props) =>
    props.gradient
      ? props.gradient
      : "linear-gradient(to top,rgba(1, 1, 1, 0),rgba(0, 0, 0, 0.72))"};
`;

const StyledLoginImage = styled(AddFlex)`
  @media screen and (max-width: 1000) {
  }
`;

const StyledMark = styled.div`
  width: 400.956px;
  height: 450.439px;
  transform: rotate(61.938deg);
  flex-shrink: 0;
  position: absolute;
  top: -340px;
  right: 0;
  border-radius: 100%;
  background-color: #225dc3;
`;

function LoginPage() {
  const [clientDetails, setClientDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [mainLoader, setMainLoader] = useState(true);
  const { isMobileView } = useWindowSize();

  const checkAuthStatus = async (uid) => {
    await customClientAxios()
      .post(CheckAuthStatus, {
        uid: uid,
      })
      .then(async (response) => {
        if (response.data.isAuthenticated) {
          window.location.href = getAdminFrontendURL();
        } else {
          await signOut(authenticate);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    customClientAxios()
      .get(FetchSubdomainClient)
      .then((response) => {
        setClientDetails(response.data);
        setMainLoader(false);
      })
      .catch((error) => {
        setClientDetails({
          name: "Growezy",
          logo: "https://firebasestorage.googleapis.com/v0/b/growezy-frontend.appspot.com/o/prakritiYoga.png?alt=media&token=a35e3bda-6453-4d97-9828-46a8db9a3be0",
          domain: "login.growezy.club",
          backgroundColor: "#6f60b2",
          sub_text: "A Growth Accelerator for Professionals",
        });
      })
      .finally(() => {
        setMainLoader(false);
      });
    authenticate.authStateReady().then((response) => {
      if (authenticate.currentUser) {
        checkAuthStatus(authenticate.currentUser.uid);
      } else {
        setLoading(false);
      }
    });
  }, []);

  return mainLoader ? (
    <AddFlex
      style={{ width: "100vw", height: "100vh" }}
      alignItems="center"
      justify="center"
    >
      <Loading />
    </AddFlex>
  ) : (
    <AddFlex height={window.innerHeight + "px"} style={{ overflow: "hidden" }}>
      <AddFlex
        flexDirection="column"
        // padding="30px"
        alignItems="center"
        justify="center"
        style={{
          position: "relative",
          height: "100%",
          width: "50vw",
          flexGrow: 0,
          backgroundColor: clientDetails.backgroundColor
            ? clientDetails.backgroundColor
            : "#2F68CC",
          display: isMobileView() && "none",
        }}
      >
        <Carousel
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          stopOnHover={false}
        >
          {clientDetails.slideShow?.map((detail) => (
            <img
              style={{ width: "40%" }}
              src={detail}
              alt={clientDetails.name}
            />
          ))}
        </Carousel>

        {/* <CustomText
          color="white"
          fontWeight="500"
          fontSize="32px"
          style={{ zIndex: 2, marginTop: "100px" }}
        >
          {clientDetails.name}
        </CustomText>
        <CustomText
          color="white"
          fontWeight="300"
          fontSize="20px"
          style={{ zIndex: 2 }}
        >
          {clientDetails.sub_text}
        </CustomText> */}
      </AddFlex>
      {loading ? (
        <AddFlex style={{ flexGrow: 1 }} alignItems="center" justify="center">
          <Loading />
        </AddFlex>
      ) : (
        <LoginWrapper clientDetails={clientDetails} />
      )}
    </AddFlex>
  );
}

export default LoginPage;
