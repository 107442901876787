import React from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { primaryActionColor } from "../constants";
import useWindowSize from "./useWindowSize";

function LoginHeader({ isLogin, showLoginView }) {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex flexDirection="column" marginBottom="0px" alignItems="center">
      <CustomText
        color={primaryActionColor}
        fontSize={isMobileView() ? "24px" : "32px"}
        style={{ fontStyle: "inter" }}
        fontWeight="600"
      >
        Welcome to Growezy !
      </CustomText>
      {/* <AddFlex flexDirection="row" justify="center" alignItems="center">
        {!isLogin && (
          <ArrowBackIcon
            sx={{
              cursor: "pointer",
              width: "max-content",
              padding: "0 8px 0 0px",
              marginLeft: "-8px",
            }}
            onClick={() => {
              showLoginView();
            }}
          />
        )}
        <CustomText color="black" fontSize="32px" fontWeight="bold">
          {!isLogin ? "Sign Up" : "Login"}
        </CustomText>
      </AddFlex> */}
    </AddFlex>
  );
}

export default LoginHeader;
