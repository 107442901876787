import styled from "styled-components";
import { inputHeight, primaryActionColor } from "./constants";
import { isNullOrUndefined } from "./utils";
import { CircularProgress, Tooltip, tooltipClasses } from "@mui/material";
import { Link } from "react-router-dom";
import { useRef } from "react";

export const AddFlex = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justify && props.justify};
  flex-wrap: ${(props) => props.wrap && props.wrap};
  flex-grow: ${(props) => props.grow && props.grow};
  position: ${(props) => (props.position ? props.position : "relative")};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.pointer && "pointer"};
  padding: ${(props) => props.padding && props.padding};
  margin-top: ${(props) => props.marginTop && props.marginTop};
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
  margin-right: ${(props) => props.marginRight && props.marginRight};
  margin: ${(props) => props.margin && props.margin};
  transition: ${(props) =>
    props.transition ? props.transition : "all 0.3s ease-in-out"};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  border-bottom: ${(props) => props.borderBottom && props.borderBottom};
  border: ${(props) => props.border && props.border};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  z-index: ${(props) => props.zIndex && props.zIndex};
  min-width: ${(props) => props.minWidth && props.minWidth};
  min-height: ${(props) => props.minHeight && props.minHeight};
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  gap: ${(props) => props.gap && props.gap};
  bottom: ${(props) => props.bottom && props.bottom};
  /* &:last-child {
    border-bottom: none;
    border: ${(props) => props.border && props.border};
  } */
  &:hover {
    color: ${(props) => props.hoverColor && props.hoverColor};
  }
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin: ${(props) => props.margin && props.margin};
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 5px;
  border: none;
  outline: none;
  border: ${(props) =>
    props.cancel
      ? "1px solid #ececec"
      : props.border
      ? props.border
      : "1px solid black"};
  background-color: ${(props) =>
    props.secondary
      ? "#fff"
      : props.backgroundColor
      ? props.backgroundColor
      : "#000"};
  color: ${(props) => (props.secondary ? "#000" : "#fff")};
  cursor: pointer;
`;

export const Group = styled.div`
  padding: ${(props) =>
    props.padding ? props.padding : "27px 41px 16px 41px"};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "8px"};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "0px 0px 4px 1px rgba(0, 0, 0, 0.05)"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "25px")};
  margin: ${(props) => props.margin && props.margin};
  @media screen and (max-width: 1000px) {
    padding: ${(props) => (props.padding ? props.padding : "15px 10px")};
  }
`;

export const InputLabel = styled.label`
  color: #344054;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 12px;
`;
export const CustomText = styled.p`
  position: ${(props) => props.position && props.position};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};
  color: ${(props) => (props.color ? props.color : "#667085")};
  font-size: ${(props) => props.fontSize && props.fontSize};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-style: ${(props) => props.fontStyle && props.fontStyle};
  margin: ${(props) => props.margin && props.margin};
  text-decoration: ${(props) => props.textDecoration && props.textDecoration};
  text-align: ${(props) => props.textAlign && props.textAlign};
  cursor: pointer;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: ${(props) => props.lineClamp && props.lineClamp};
  -webkit-line-clamp: ${(props) => props.lineClamp && props.lineClamp};
  -webkit-box-orient: vertical;
`;

export const CustomInput = ({
  value,
  onClick,
  onChange,
  label,
  type,
  max,
  min,
  placeholder,
  widget,
  width,
  footerText,
  footerTextColor,
  leftWidget,
  margin,
  inputStyle,
  style,
  disabled,
  backgroundColor,
}) => {
  const inputRef = useRef();
  return (
    <AddFlex
      margin={margin}
      flexDirection="column"
      width={width ? width : "100%"}
      onClick={(e) => {
        if (isNullOrUndefined(onClick)) {
          return;
        } else {
          inputRef.current.focus();
          onClick(e);
        }
      }}
      style={style}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <AddFlex
        backgroundColor={backgroundColor ? backgroundColor : "white"}
        width="100%"
        border="1px solid #d0d5dd"
        margin="0px 0 0 0"
        borderRadius="5px"
        style={inputStyle}
      >
        {leftWidget && (
          <AddFlex
            justify="center"
            alignItems="center"
            backgroundColor={backgroundColor}
            style={{
              padding: "5px",
              minWidth: "40px",
              borderRadius: "5px 0px 0px 5px",
              height: inputHeight,
              // backgroundColor: "#ececec",
              borderRight: "1px solid #ececec",
            }}
          >
            {leftWidget}
          </AddFlex>
        )}
        <input
          value={value}
          ref={inputRef}
          type={type}
          max={max}
          min={min}
          disabled={disabled}
          placeholder={placeholder}
          style={{
            flexGrow: 1,
            borderRadius: "5px",
            outline: "none",
            backgroundColor: backgroundColor ? backgroundColor : "white",
            border: "none",
            padding: "10px 15px",
            height: inputHeight,
          }}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
        />
        {widget && (
          <AddFlex
            justify="center"
            alignItems="center"
            backgroundColor={backgroundColor}
            style={{
              padding: "10px",
              minWidth: "40px",
              borderRadius: "0 5px 5px 0",
              height: inputHeight,
              // backgroundColor: "#ececec",
              borderLeft: "1px solid #ececec",
            }}
          >
            {widget}
          </AddFlex>
        )}
      </AddFlex>
      {footerText && (
        <CustomText fontSize="13px" margin="8px 0 0 0" color={footerTextColor}>
          {footerText}
        </CustomText>
      )}
    </AddFlex>
  );
};

const Footer = ({ isLoading, onSubmit, closeDialog }) => {
  return (
    <AddFlex width="100%" padding="10px">
      <ActionButton
        onClick={closeDialog}
        secondary
        style={{ width: "50%", marginRight: " 10px" }}
      >
        Cancel
      </ActionButton>
      <ActionButton onClick={onSubmit} style={{ width: "50%" }}>
        {isLoading && (
          <CircularProgress
            size={18}
            sx={{ color: "white", marginRight: "10px" }}
          />
        )}
        <CustomText color="white">Block dates</CustomText>
      </ActionButton>
    </AddFlex>
  );
};
export const StyledLink = styled(Link)`
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => props.fontSize && props.fontSize};
  display: flex;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : "6px 6px 6px 14px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 0")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  border-radius: 4px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  text-decoration: none;
  &:hover {
    /* background-color: #f9f5ff; */
  }
`;

export const CustomInputArea = styled.textarea`
  resize: none;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 90px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #dddddd; // Initial border color
  border-radius: 5px; // Rounded corners for the textarea
  resize: none; // Removes the default resize handle
  outline: none; // Removes the outline on focus
  transition: border-color 0.3s; // Smooth transition for border color change

  &:focus {
    border-color: #26a68b; // Change border color on focus
  }
`;

export const CustomSimpleInput = styled.input`
  resize: none;
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: 16px;
  padding: 10px;
  border: 1px solid #dddddd; // Initial border color
  border-radius: 5px; // Rounded corners for the textarea
  resize: none; // Removes the default resize handle
  outline: none; // Removes the outline on focus
  transition: border-color 0.3s; // Smooth transition for border color change
  &:focus {
    border-color: ${(props) =>
      props.borderColor
        ? props.borderColor
        : primaryActionColor}; // Change border color on focus
  }
`;

export const FieldLabelText = styled.label`
  font-weight: 500;
  color: ${"#344054"};
  font-size: 14px;
  margin-bottom: 0px;
  margin: ${(props) => props.margin && props.margin};
`;

export const CustomInputLabel = styled.label`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : "#344054")};
  display: ${(props) => (props.display ? props.display : "block")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "8px"};
  font-family: ${(props) => (props.font ? props.font : "Roboto, 'sans-serif'")};
`;
export const CustomButton = styled.button`
  font-size: 16px;
  cursor: pointer;
  padding: 6px 25px;
  width: max-content;
  background-color: ${(props) =>
    props.buttonType === "solid" ? props.color : "#ffffff"};
  border: 0.5px solid
    ${(props) => (props.buttonType === "solid" ? props.color : "#444444")};
  color: ${(props) => (props.buttonType === "solid" ? "#ffffff" : "#444444")};
`;

export const PrimaryActionButton = styled.button`
  display: flex;
  outline: none;
  border: none;
  padding: ${(props) => (props.padding ? props.padding : "8px 32px")};
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#0066da"};
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  line-height: 18px; /* 128.571% */
  margin: ${(props) => props.margin && props.margin};
`;

export const SecondaryActionButton = styled.div`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "8px 32px")};
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid
    ${(props) => (props.color ? props.color : primaryActionColor)};
  background: #fff;
  color: ${(props) => (props.color ? props.color : primaryActionColor)};
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  line-height: 18px; /* 128.571% */
  margin: ${(props) => props.margin && props.margin};
`;

export const Gap = styled.div`
  flex-grow: 1;
`;

export const Header = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 90% */
  letter-spacing: -0.4px;
`;

export const SubHeader = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 90% */
  letter-spacing: -0.4px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  @media screen and (max-width: 1024px) {
    width: 18px;
    height: 18px;
  }
`;

export const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => (props.color ? props.color : "#eaecf0")};
`;

export const CustomToolTip = styled(Tooltip)`
  & .${tooltipClasses.tooltip} {
    font-family: "Outfit";
  }
`;

export const Arrow = styled.span`
  color: ${primaryActionColor};
  font-size: 1.3rem;
`;

export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`;
const HeaderDescription = styled.div`
  font-size: 14px;
  margin: 4px 0 0 0;
  width: 70%;
  color: #667085;
`;

const Wrapper = styled.div`
  padding-left: 16px;
  border-left: 4px solid ${primaryActionColor};
`;

export const SectionHeaderFancy = ({ Title, Description }) => {
  return (
    <Wrapper>
      <HeaderTitle>{Title}</HeaderTitle>
      <HeaderDescription>{Description}</HeaderDescription>
    </Wrapper>
  );
};

export const ContentHeader = styled.p`
  margin-bottom: 5px;
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin && props.margin};
  color: ${(props) => (props.color ? props.color : "#101828")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
`;

export const FullPageParent = styled(AddFlex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
