import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { Route, Routes } from "react-router";
import LoginPage from "./components/LoginPage";
import MainPage from "./components/MainPage";
import VerifyEmail from "./components/VerifyEmail";
function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
      maxSnack={3}
      autoHideDuration={3000}
      hideIconVariant={true}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/verify" element={<VerifyEmail />} />
          {/* <Route path="/main" element={<MainPage />} /> */}
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
