import axios from "axios";

export const getBackendBaseURL = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://dev-dot-growezy-backend-396213.el.r.appspot.com/";
  } else {
    return "http://localhost:3001/";
  }
};

export const getAdminFrontendURL = () => {
  if (process.env.NODE_ENV === "production") {
    return "https://app.growezy.club";
  } else {
    return "http://localhost:3002";
  }
};

export default axios.create({
  baseURL: getBackendBaseURL(),
});
