import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import React, { useEffect, useState } from "react";
import { authenticate } from "../firebase-config";
import { AddFlex, CustomText } from "../reusableStyles";
import { enqueueSnackbar } from "notistack";
import { Player } from "@lottiefiles/react-lottie-player";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

const Circle = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  border-radius: 50%;
  z-index: 1111;
  background: #2f68cc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function VerifyEmail({ authenticateUser }) {
  const [email, setEmail] = useState(localStorage.getItem("emailForSignIn"));
  const [askMail, setAskMail] = useState(false);
  const [loading, setLoading] = useState(-1);
  const isVerified = () => {
    return loading === 0;
  };
  const handleVerifyEmail = () => {
    if (isSignInWithEmailLink(authenticate, window.location.href)) {
      if (!email) {
        alert("Enter your email");
        setAskMail(true);
        return;
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(authenticate, email, window.location.href)
        .then(async (loginResponse) => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          setLoading(0);
          await authenticateUser(loginResponse);
        })
        .catch((error) => {
          console.log(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          enqueueSnackbar("Something Went Wrong", {
            variant: "error",
          });
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleVerifyEmail();
    }, 1000);
  }, []);

  return (
    <AddFlex
      position="fixed"
      flexDirection="column"
      top="0px"
      left="0px"
      alignItems="center"
      justify="center"
      backgroundColor="#2f68cc"
      height="100vh"
      width="100vw"
      style={{ zIndex: 111111 }}
    >
      <AddFlex style={{ flex: 0.5 }} alignItems="flex-end">
        {loading === 0 && (
          <Player
            autoplay
            loop
            src={
              "https://lottie.host/8986aa5d-4f98-45ac-8134-b758efab4365/gkKkauYmCV.json"
            }
            style={{ margin: 0 }}
          ></Player>
        )}
        {loading === -1 && (
          <CircularProgress size={40} sx={{ color: "white" }} />
        )}
      </AddFlex>
      <CustomText
        color="white"
        style={{ lineHeight: "1.4", flex: 0.5 }}
        fontSize="32px"
        fontWeight="400"
        textAlign="center"
      >
        {loading === 0 ? "You are Verified" : "Verifying Your Email"}
      </CustomText>
    </AddFlex>
  );
}

export default VerifyEmail;
