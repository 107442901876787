import React, { useEffect, useState } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import LoginHeader from "./LoginHeader";
import EmailLoginComponent from "./EmailLoginComponent";
import GoogleLoginComponent from "./GoogleLoginComponent";
import styled from "styled-components";
import { signOut } from "firebase/auth";
import { authenticate } from "../firebase-config";
// import { logout } from "../../utils";
// import { useDispatch } from "react-redux";
import customClientAxios from "../clientAxios";
import { LoginAuthenticateApi } from "../ApiEndPoints";
import { getAdminFrontendURL } from "../axios";
import DialogHolder from "./DialogHolder";
import OtherDomainLoginDialog from "./OtherDomainLoginDialog";
import { useSearchParams } from "react-router-dom";
import VerifyEmail from "./VerifyEmail";
import growezyLogo from "../ImageAssets/growezyLogo.png";
import useWindowSize from "./useWindowSize";
import { CircularProgress } from "@mui/material";

const LoginComponentHolder = styled(AddFlex)`
  width: 100%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledLeftSide = styled(AddFlex)`
  align-items: center;
  // justify="center"
  height: 100%;
  flex-grow: 1;
  position: relative;
  overflow: scroll;
  margin: "40px 0 0 0";
  padding: 40px;
  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

function LoginWrapper({ clientDetails }) {
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showDomainLoginErrorInfoDialog, setShowDomainLoginErrorInfoDialog] =
    useState(false);
  const [userSignedDomain, setUserSignedDomain] = useState();
  const [searchParams] = useSearchParams();
  const { isMobileView } = useWindowSize();
  const authenticateUser = async (loginResponse) => {
    setLoading(true);
    const user = loginResponse.user;
    const userPayload = {
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      domain: window.location.hostname,
    };
    const token = await user.getIdToken(true);
    customClientAxios()
      .post(LoginAuthenticateApi, userPayload, {
        // withCredentials: true,
        headers: {
          authorization: `${token}`,
        },
      })
      .then(async (response) => {
        if (!response.data.authenticate) {
          await signOut(authenticate).then((res) => {
            console.log("User logged out");
          });
          setUserSignedDomain(response.data.domain);
          setShowDomainLoginErrorInfoDialog(true);
          return;
        }
        if (searchParams.get("isVerify")) {
          setTimeout(() => {
            window.location.href =
              getAdminFrontendURL() + "?token=" + response.data.token;
          }, 1000);
        } else {
          window.location.href =
            getAdminFrontendURL() + "?token=" + response.data.token;
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Login failed. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const logoutUser = async () => {
    await signOut(authenticate);
  };

  useEffect(() => {
    const isVerify = searchParams.get("isVerify");
  }, []);

  return loading ? (
    <AddFlex
      flexDirection="column"
      grow={1}
      justify="center"
      alignItems="center"
    >
      <CircularProgress />
      <CustomText>Please Wait</CustomText>
    </AddFlex>
  ) : searchParams.get("isVerify") ? (
    <VerifyEmail authenticateUser={authenticateUser} isLogin={isLogin} />
  ) : (
    <>
      <AddFlex
        flexDirection="column"
        alignItems="center"
        // justify="center"
        height="100%"
        style={{
          flexGrow: 1,
          position: "relative",
          overflow: "scroll",
          zIndex: 111,
          margin: "40px 0 0 0",
        }}
        padding="40px"
      >
        <AddFlex flexDirection="column" width={isMobileView() ? "100%" : "60%"}>
          <img
            src={growezyLogo}
            style={{
              height: "35px",
              width: "174px",
              margin: "0 auto 30px auto",
            }}
          />
          <LoginHeader
            isLogin={isLogin}
            showLoginView={() => setIsLogin(true)}
          />
          <EmailLoginComponent
            authenticateUser={authenticateUser}
            isLogin={isLogin}
          />
          {isLogin && (
            <>
              <LoginComponentHolder
                margin="60px 0"
                justify="space-evenly"
                alignItems="center"
              >
                <div
                  style={{
                    height: "3px",
                    width: "30%",
                    backgroundColor: "#efefef",
                  }}
                ></div>
                <CustomText margin="0px" color="#cfcfcf">
                  OR
                </CustomText>
                <div
                  style={{
                    height: "3px",
                    width: "30%",
                    backgroundColor: "#efefef",
                  }}
                ></div>
              </LoginComponentHolder>
              <GoogleLoginComponent authenticateUser={authenticateUser} />
            </>
          )}
        </AddFlex>
      </AddFlex>
      <DialogHolder
        content={
          <OtherDomainLoginDialog
            domain={userSignedDomain}
            redirectUrl={() => {
              window.location.href = "https://" + userSignedDomain;
            }}
          />
        }
        openDialog={showDomainLoginErrorInfoDialog}
        radius="28px"
        setOpenDialog={setShowDomainLoginErrorInfoDialog}
      />
    </>
  );
}

export default LoginWrapper;
