import axios from "axios";
import { getBackendBaseURL } from "./axios";

const customClientAxios = () => {
  const axiosInstance = axios.create({ baseURL: getBackendBaseURL() });
  axiosInstance.interceptors.request.use(
    async (config) => {
      // Modify config to add headers or perform other actions
      config.cache = false;
      config.headers.srcdomain = window.location.hostname;
      // config.headers["userid"] = userSliceData.firebaseUserData.uid;
      // config.headers["userid"] = userSliceData.firebaseUserData.uid;
      // if (isNullOrUndefined(config.headers["authorization"])) {
      //   config.headers["authorization"] = userSliceData.token;
      // }
      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export default customClientAxios;
